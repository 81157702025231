<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.DisplayName">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.DisplayName }}</p>
      <small>{{$t('_profile_dropdown.Available')}}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <img
          v-if="activeUserInfo.Avatar"
          key="onlineImg"
          :src="activeUserInfo.Avatar"
          alt="user-img"
          width="40"
          height="40"
          class="rounded-full shadow-md cursor-pointer block"
        />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/user/profile').catch(() => {})"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{$t('_profile_dropdown.Profile')}}</span>
          </li>

<!--          <v-tooltip left>-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <li v-on="on" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--                &lt;!&ndash; @click="$router.push('/apps/email').catch(() => {})" &ndash;&gt;-->
<!--                <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />-->
<!--                <span class="ml-2" title="Under Construction">Inbox</span>-->
<!--              </li>-->
<!--            </template>-->
<!--            <span>Under Construction</span>-->
<!--          </v-tooltip>-->

<!--          <v-tooltip left>-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <li v-on="on" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--                &lt;!&ndash; @click="$router.push('/apps/todo').catch(() => {})" &ndash;&gt;-->
<!--                <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />-->
<!--                <span class="ml-2">Tasks</span>-->
<!--              </li>-->
<!--            </template>-->
<!--            <span>Under Construction</span>-->
<!--          </v-tooltip>-->

<!--          <v-tooltip left>-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <li v-on="on" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--                &lt;!&ndash; @click="$router.push('/apps/chat').catch(() => {})" &ndash;&gt;-->
<!--                <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />-->
<!--                <span class="ml-2">Chat</span>-->
<!--              </li>-->
<!--            </template>-->
<!--            <span>Under Construction</span>-->
<!--          </v-tooltip>-->

<!--          <v-tooltip left>-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <li v-on="on" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--                &lt;!&ndash; @click="$router.push('/apps/eCommerce/wish-list').catch(() => {})" &ndash;&gt;-->
<!--                <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />-->
<!--                <span class="ml-2">Wish List</span>-->
<!--              </li>-->
<!--            </template>-->
<!--            <span>Under Construction</span>-->
<!--          </v-tooltip>-->

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{$t('_profile_dropdown.Logout')}}</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
//  import firebase from 'firebase/app'
//  import 'firebase/auth'

export default {
  data() {
    return {};
  },
  computed: {
    activeUserInfo() {
      let activeUserInfo = this.$store.state.AppActiveUser;
      return activeUserInfo;
    }
  },
  mounted() {
    this.$goc.setModule("ProfileDropDown", this);
  },
  methods: {
    logout() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userInfo");
      let state = this.$store.state;
      let newState = {};
      Object.keys(state).forEach(key => {
        if (key === "AppActiveUser") {
          newState[key] = null; // or = initialState[key]
        } else {
          newState[key] = state[key];
        }
      });

      this.$store.replaceState(newState);

      this.$keycloak.logoutFn({
        redirectUri: window.location.href
      });
      // if user is logged in via auth0
      // if (this.$auth.profile) this.$auth.logOut();

      // if user is logged in via firebase
      // const firebaseCurrentUser = firebase.auth().currentUser

      // if (firebaseCurrentUser) {
      //     firebase.auth().signOut().then(() => {
      //         this.$router.push('/pages/login').catch(() => {})
      //     })
      // }
      // If JWT login
      if (
        localStorage.getItem("accessToken") ||
        localStorage.getItem("userInfo")
      ) {
        this.$router.push("/pages/login").then(() => {
          // Change role on logout. Same value as initialRole of acj.js
          // this.$store.dispatch('updateUserRole', {aclChangeRole: this.$acl.change, role: "None"});
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userInfo");

          /** clear state */
          let state = this.$store.state;
          let newState = {};

          Object.keys(state).forEach(key => {
            if (key === "AppActiveUser") {
              newState[key] = null; // or = initialState[key]
            } else {
              newState[key] = state[key];
            }
          });

          this.$store.replaceState(newState);

          window.location.reload();
        });
      }
    }
  }
};
</script>
